var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contract-detail" },
    [
      _c("app-detail", { attrs: { opt: _vm.opt } }),
      _c(
        "div",
        { staticClass: "contract-detail__box" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "解约类型", prop: "value" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.value,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "value", $$v)
                        },
                        expression: "ruleForm.value"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "解约说明", prop: "des" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 4 },
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.ruleForm.des,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "des", $$v)
                      },
                      expression: "ruleForm.des"
                    }
                  })
                ],
                1
              ),
              _c(
                "footer",
                { staticClass: "action-but" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("确认解约")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }