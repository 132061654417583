<template>
  <div class="contract-detail">
    <app-detail :opt="opt"></app-detail>

    <div class="contract-detail__box">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="解约类型" prop="value">
          <el-select v-model="ruleForm.value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="解约说明" prop="des">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="ruleForm.des"
          ></el-input>
        </el-form-item>
        <footer class="action-but">
          <el-button type="primary" @click="onSubmit">确认解约</el-button>
        </footer>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "合同信息",
        form: [
          {
            key: "createdDate",
            label: "签约时间"
          },
          {
            key: "id",
            label: "合同编号"
          },
          {
            key: "apartmentName",
            label: "房屋详情"
          },
          {
            key: "name",
            label: "签约人"
          },
          {
            key: "gender",
            label: "性别"
          },
          {
            key: "idcardno",
            label: "身份证号"
          },
          {
            key: "rentalFee",
            label: "月租金"
          }
        ],
        get(opt) {
          let data = _this.cache.get("tenantDetailContract", {});
          opt.cb(data);
        }
      },
      options: [],
      ruleForm: {
        value: "",
        des: ""
      },
      rules: {
        value: [{ required: true, message: "请选择解约类型", trigger: "blur" }]
      }
    };
  },
  created: function() {
    console.log("sale-detail created!!");
    this.getTypeList();
  },
  methods: {
    onSubmit() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          let data = this.cache.get("tenantDetailContract", {});
          let dto = {
            contractId: data.id,
            rescindType: this.ruleForm.value,
            remark: this.ruleForm.des
          };
          this.post(
            "/tenant-service/leasecontract/cancelLeaseContractForce",
            dto,
            {
              operatorFlage: true,
              operateName: '强制解约',
            }
          ).then(data => {
            this.$message({
              showClose: true,
              message: "成功解约",
              type: "success"
            });
            this.$back();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    getTypeList() {
      let dto = {
        type: "解约类型"
      };
      this.post("/user-service/dictionary/selectDictionary", dto).then(data => {
        let _options = [];
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          _options.push({
            label: item.name,
            value: item.value
          });
        }
        this.options = _options;
      });
    }
  }
};
</script>
<style lang="scss">
.contract-detail__box {
  margin: 0 auto;
  max-width: 900px;
}
</style>
